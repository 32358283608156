<template>
  <section class="containerDetailPage" id="containerDetailPage">
    <backToTopButton @scroll-to-top="scrollToTop" :scrollY="scrollY" />
    <section class="containerDetailPage__info">
      <backButton
        @goBack="goBack"
        altRedirection="resultsIntelligence"
        altLabel="INTELLIGENCE"></backButton>
      <section v-if="infoLoaded" class="containerDetailPage__info--contentData">
        <section class="content">
          <section class="content__title">
            <h2 class="content__title--label">{{resultItem.title}}</h2>
            <optionsCard class="content__title--optionsCard" v-if="resultItem.id"
              @openCollectionsModal="openCollectionsModal"
              @openAddToPortfolioModal="openAddToPortfolioModal"
              @openDeleteModal="openDeleteModal"
              :id="resultItem.id"
              :source="resultItem.source"
            >
            </optionsCard>
          </section>
          <request-to-access :cardId="route.params.idCard" :requestAccessObject="requestAccessObject"></request-to-access>
          <figure :class="[itemHasStreamingUrls ? 'detailIntellVideo' : 'content__image']">
            <section class="detailIntellVideo__video" v-if="itemHasStreamingUrls && requestAccessObject.allowedView">
              <video id="azuremediaplayer" class="azuremediaplayer amp-default-skin amp-big-play-centered" playsinline tabindex="0"/>
            </section>
            <cardImages v-if="(!itemHasStreamingUrls || !requestAccessObject.allowedView) && !Array.isArray(resultItem)" :item="resultItem"></cardImages>
          </figure>
          <section class="content__reactionsDate">
            <section class="content__reactionsDate--dateRegionContent">
              <div class="date">
                <span class="date__label">{{resultItem.date_created}}</span>
              </div>
              <div class="region">
                <span class="region__label" v-if="resultItem.agency">{{store.getters.parseArrayToString(resultItem.agency)}}</span>
              </div>
            </section>
            <reactionsBox class="reactions"
              v-if="resultItem.id"
              :itemId="route.params.idCard"
              likesDetail
              @openLikesModal="toggleLikesModal(true)"
            />
          </section>
          <section class="content__description">
            <span class="content__description--label title">Summary: </span>
            <span v-html="resultItem.description" class="content__description--label"></span>
          </section>
          <section class="content__fields">
            <ul class="content__fields--list">
              <li class="label">
                <span class="label__title">File Type: </span>{{resultItem.file_type}}
              </li>
              <li class="label">
                <span class="label__title">Year Created: </span>{{resultItem.publish_year}}
              </li>
              <li class="label">
                <span class="label__title">Source / Author: </span>
                <a v-for="(item, index) in resultItem.author" :key="index" :id="`author-${index}`"
                  :class="[item.displayUserInfo ? 'peopleName' : 'peopleName--terminated']"
                  target="_self"
                  @mouseover="openPopupPersonalInfo($event, item.displayUserInfo, index, item.eccode, 'author')"
                  @mouseleave="openPopupPersonalInfo($event, false, index, item.eccode, 'author')"
                  :href="item.displayUserInfo ? '' : false"
                  @click="linkProfileUser(item.eccode, $event, item.displayUserInfo)"
                  @contextmenu="rightClick($event, item.eccode)"
                >
                  {{item.name}}
                  <popupPersonalInfoGeneric
                    v-if="openPopup && position === `author-${index}` && userDataLoaded"
                    :popupPosition="popupPosition"
                    :personalInfoProp="store.state.managerInfo"
                    :profileLink="profileLink(item.eccode)"
                    :isSearchApp="true"
                    @click.stop.prevent>
                  </popupPersonalInfoGeneric>
                </a>
              </li>
              <li class="label">
                <span class="label__title">Collaborators: </span>
                <a v-for="(item, index) in resultItem.collaborators" :key="index" :id="`people-${index}`"
                  :class="[item.displayUserInfo ? 'peopleName' : 'peopleName--terminated']"
                  target="_self"
                  @mouseover="openPopupPersonalInfo($event, item.displayUserInfo, index, item.eccode, 'people')"
                  @mouseleave="openPopupPersonalInfo($event, false, index, item.eccode, 'people')"
                  :href="item.displayUserInfo ? '' : false"
                  @click="linkProfileUser(item.eccode, $event, item.displayUserInfo)"
                  @contextmenu="rightClick($event, item.eccode)"
                >
                  {{item.name}}<span class="peopleName__comma" v-if="index != resultItem.collaborators.length - 1">, </span>
                  <popupPersonalInfoGeneric
                    v-if="openPopup && position === `people-${index}` && userDataLoaded"
                    :popupPosition="popupPosition"
                    :personalInfoProp="store.state.managerInfo"
                    :profileLink="profileLink(item.eccode)"
                    :isSearchApp="true"
                    @click.stop.prevent>
                  </popupPersonalInfoGeneric>
                </a>
              </li>
              <li class="label" v-if="resultItem.media">
                <span class="label__title">Media: </span>{{store.getters.parseArrayToString(resultItem.media)}}
              </li>
              <li class="label">
                <span class="label__title">Content: </span><linkFilter :label="resultItem.content_type" propertyName="content_type"/>
              </li>
              <li class="label" v-if="resultItem.client">
                <span class="label__title">Client: </span><linkFilter :label="resultItem.client[0]" propertyName="client"/>
              </li>
              <li class="label" v-if="resultItem.brand">
                <span class="label__title">Brand: </span><linkFilter :label="resultItem.brand[0]" propertyName="brand"/>
              </li>
              <li class="label" v-if="resultItem.business_region.length">
                <span class="label__title">Region: </span> {{store.getters.parseArrayToString(resultItem.business_region)}}
              </li>
              <li class="label" v-if="resultItem.country">
                <span class="label__title">Country: </span> {{resultItem.country[0]}}
              </li>
              <li class="label" v-if="resultItem.agency.length">
                <span class="label__title">Agency / Office: </span><linkFilter v-for="(item, index) in resultItem.agency" :key="index" :label="item" propertyName="agency"/>
              </li>
              <li class="label" v-if="resultItem.categories">
                <span class="label__title">Industry / Category: </span><linkFilter v-for="(item, index) in resultItem.categories" :key="index" :label="item" propertyName="categories"/>
              </li>
              <li class="label" v-if="resultItem.tags">
                <span class="label__title">Tags: </span>{{store.getters.parseArrayToString(resultItem.tags)}}
              </li>
            </ul>
          </section>
          <commentsBox :articleId="route.params.idCard" :comments="comments" @updateCommentList="updateCommentList" @updateCommentContent="updateCommentContent">
          </commentsBox>
        </section>
      </section>
    </section>
    <section v-if="infoLoaded" class="containerDetailPage__similarAssets">
      <div class="containerDetailPage__similarAssets--background">
        <similar-assets :articleId="route.params.idCard" stylesFrom="detail"></similar-assets>
      </div>
    </section>
    <skeletonDetailPage v-if="!infoLoaded" />
    <addRemoveItemsToCollectionGeneric
      v-if="store.state.collectionsInfo.isCollectionModalOpened"
      @goToNewCollection="forwardToCreateCollectionModal"
      @close="openCollectionsModal(null, false)"
      :cardsId="[store.state.collectionsInfo.cardId]"
      :collectionsList="store.state.collectionsMine"
      @updateCollectionContainId="updateCollectionContainId($event)">
    </addRemoveItemsToCollectionGeneric>
    <createCollectionModalGeneric
      v-if="createCollectionModalVisible"
      :creatingWithItems="true"
      :cardsId="[resultItem.id]"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="backToCollectionModal"
      @close="toggleCreateCollectionsModal(false)"
    ></createCollectionModalGeneric>
    <addItemToPortfolio ref="portfolioModal"/>
    <deleteItem ref="deleteItemModal"
      :source="resultItem.source"
      detailPage
      @showDeleteErrorToast="showDeleteErrorToast"
    />
    <peopleListModalGeneric v-if="likesModalVisible" :itemId="route.params.idCard" @close="toggleLikesModal(false)"></peopleListModalGeneric>
    <toast v-if="deleteErrorToast" :class="{'marginToolToast': !store.state.isMobile}"
      :message="errorMessageToast">
    </toast>
  </section>
</template>

<script>
import { onMounted, ref, reactive, computed, nextTick, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import requestToAccess from '@/components/requestToAccess/requestToAccess'
import reactionsBox from '@/components/reactionsBox/reactionsBox'
import { fieldsForDetailPage } from '@/store/store'
import optionsCard from '@/components/optionsCard/optionsCard'
import linkFilter from '@/components/linkFilter/linkFilter'
import addItemToPortfolio from '@/components/addItemToPortfolioModal/addItemToPortfolioModal'
import deleteItem from '@/components/deleteItemModal/deleteItemModal'
import messages from '../../messages.json'
import similarAssets from '@/components/similarAssets/similarAssets'
import commentsBox from '@/components/commentsBox/commentsBox'
import skeletonDetailPage from '@/components/skeletons/skeletonDetailPage/skeletonDetailPage'
import cardImages from '@/components/cardImages/cardImages'
import backButton from '@/components/backButton/backButton'
import { setUpAzureMediaPlayer } from '@/utils/azureMediaService'
import $ from 'jquery'

export default {
  name: 'detailPage',
  components: {
    requestToAccess,
    reactionsBox,
    optionsCard,
    linkFilter,
    addItemToPortfolio,
    deleteItem,
    similarAssets,
    commentsBox,
    skeletonDetailPage,
    cardImages,
    backButton
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const resultItem = ref([])
    const openPopup = ref(false)
    const popupPosition = reactive({
      right: 0,
      top: 0,
      bottom: 0,
      left: 0
    })
    const requestControl = ref(null)
    const position = ref(null)
    const authorsCollaboratorsInfo = reactive({})
    const errorMessageToast = ref(messages.generic.errorMessageToast)
    const deleteErrorToast = ref(null)
    const likesModalVisible = ref(false)
    const article = ref(null)
    const comments = ref([])
    const infoLoaded = ref(false)
    const portfolioModal = ref(null)
    const deleteItemModal = ref(null)
    const createCollectionModalVisible = ref(false)
    const requestAccessObject = ref(null)
    const azureMediaPlayer = reactive({})
    const userDataLoaded = ref(false)
    const scrollY = ref(0)

    /**
     * @description Returns a flag to know if the current item has the stremaing urls.
     */
    const itemHasStreamingUrls = computed(() => {
      return requestAccessObject.value.generatedStreamingUrls && requestAccessObject.value.generatedStreamingUrls.length
    })

    /**
     * @description Get user profile link
     * @param id User id
     */
    const profileLink = computed(() => (id) => {
      return process.env.VUE_APP_NODE_ENV === 'prd' ? `https://people.grey.com/profile/${id}` : `https://${process.env.VUE_APP_NODE_ENV}-people.grey.com/profile/${id}`
    })

    onMounted(() => {
      setUpDetailPage()
      setBackToTopButton()
    })

    onUnmounted(() => {
      if (azureMediaPlayer.value && Object.values(azureMediaPlayer.value).length > 0) azureMediaPlayer.value.dispose()
    })

    /**
     * @description gets main container to be able to listen to its scroll
     */
    function setBackToTopButton () {
      let mainSpace
      if (window.innerWidth < 1024) {
        mainSpace = document.querySelector('.topSpaceMobile')
      } else {
        mainSpace = document.querySelector('.leftSpaceDesktop')
      }
      mainSpace.addEventListener('scroll', () => {
        scrollY.value = mainSpace.scrollTop
      })
    }

    /**
     * @description scrolls the page to the top
     */
    function scrollToTop () {
      $('html, #app').animate({ scrollTop: 0 }, 500)
    }

    /**
     * @description Init render view
     */
    function setUpDetailPage () {
      store.dispatch({
        type: 'getDetailbyId',
        id: route.params.idCard,
        fields: fieldsForDetailPage
      }).then(
        (response) => {
          response.date_created = store.getters.formatDate(response.date_created)
          if (response.previews.length) {
            response.previews = null
          }
          resultItem.value = response
          resultItem.value.author = authorsCollaboratorsInfo.authors
          resultItem.value.collaborators = authorsCollaboratorsInfo.collaborators
          store.dispatch({
            type: 'updateViews',
            id: route.params.idCard
          })
          getDetailViewAndComments()
        }
      )
    }

    /**
     * @description Method that response the detail view and comments
     */
    function getDetailViewAndComments () {
      callGetDetailComments().then((detailComments) => {
        comments.value = detailComments.data.comments
        store.dispatch({
          type: 'fileAccess',
          indexId: route.params.idCard
        }).then(
          (response) => {
            requestAccessObject.value = response.data

            authorsCollaboratorsInfo.value = response.data
            resultItem.value.author = response.data.authors
            resultItem.value.collaborators = response.data.collaborators
            infoLoaded.value = true
            nextTick(() => {
              if (itemHasStreamingUrls.value) {
                const captionsArray = []
                if (requestAccessObject.value.closedCaptions) {
                  requestAccessObject.value.closedCaptions.forEach((closedCaption) => {
                    captionsArray.push({
                      kind: 'captions',
                      src: closedCaption.url,
                      srclang: closedCaption.language,
                      label: closedCaption.tag
                    })
                  })
                }
                azureMediaPlayer.value = setUpAzureMediaPlayer(requestAccessObject.value.generatedStreamingUrls[0], requestAccessObject.value.mimeType, captionsArray, requestAccessObject.value.thumbnailUrl)
                setTimeout(() => {
                  const muteUnmuteControl = document.getElementsByClassName('vjs-volume-control')[0]
                  muteUnmuteControl.classList.remove('vjs-hidden')
                }, 100)
              }
            })
          }
        )
      }).catch(error => {
        console.error(error)
      })
    }

    /**
     * @description Call and returns getDetailComments from storage
     */
    function callGetDetailComments () {
      return store.dispatch({
        type: 'getDetailComments',
        idCard: route.params.idCard
      })
    }

    /**
     * @description Open collectons modal
     * @param id From card
     * @param flag true or false to know if modal is open or close
     */
    function openCollectionsModal (id, flag) {
      store.commit({
        type: 'SET_COLLECTION_INFO',
        id: id,
        flag: flag
      })
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Go Back to collection Modal and reload collections
     */
    function backToCollectionModal () {
      store.dispatch({
        type: 'getCollections',
        ids: [resultItem.value.id]
      }).then(
        (response) => {
          toogleCollectionsModal(resultItem.value.id, true)
          toggleCreateCollectionsModal(false)
        }
      )
    }

    /**
     * @description Go to Create collectionModal
     */
    function forwardToCreateCollectionModal () {
      toogleCollectionsModal(null, false)
      toggleCreateCollectionsModal(true)
    }

    /**
     * @description Toogle CollectionsModal visibility
     * @param id of the card that will be added.
     * @param flag to control open or close modal.
     */
    function toogleCollectionsModal (id, flag) {
      store.commit({
        type: 'SET_COLLECTION_INFO',
        id: id,
        flag: flag
      })
    }

    /**
     * @description open add to my portfolio modal confirmation.
     * @param {mode} string to indicate if message will be about removing or adding an item.
     */
    function openAddToPortfolioModal (mode) {
      portfolioModal.value.open(mode)
    }

    /**
     * @description Open delete item confirmation modal
     */
    function openDeleteModal () {
      deleteItemModal.value.open(resultItem.value.id)
    }

    /**
     * @description Sets local store flag to indicate if restore or not scroll position
     */
    function setScrollRestoreFlag () {
      localStorage.setItem('restoreScrollTop', true)
    }

    /**
     * @description Actions on back click
     */
    function goBack () {
      if (azureMediaPlayer.value && Object.values(azureMediaPlayer.value).length > 0) azureMediaPlayer.value.dispose()
      setScrollRestoreFlag()
    }

    /**
     * @description Open and positioned popup depending coordenates to cursor and space free
     * @param event of DOM
     * @param {Bool} flag to show or hide modal
     * @param index Position in array
     * @param eccode of user
     * @param peopleType HTML ID people to collaborators and author to authors
     */
    function openPopupPersonalInfo (event, flag, index, eccode, peopleType) {
      position.value = !event.target.id ? position.value : event.target.id
      const link = peopleType === 'people' ? document.getElementById(`people-${index}`) : document.getElementById(`author-${index}`)
      const rect = link.getBoundingClientRect()
      const bottomSpace = window.innerHeight - rect.bottom
      const rightSpace = window.innerWidth - rect.right
      const topSpace = rect.top

      if (flag && store.state.managerInfo.id !== eccode) {
        userDataLoaded.value = false
        store.commit({
          type: 'GET_MANAGER_INFO',
          managerInfo: {},
          namePositionNumber: null
        })
      }

      if (flag && store.state.managerInfo.namePositionNumber !== index && eccode) {
        store.dispatch({
          type: 'getManagerData',
          ecCode: eccode,
          namePositionNumber: event.target.id
        }).then(result => {
          if (!result) flag = result
          else {
            userDataLoaded.value = true
          }
        })
      }

      if (!navigator.userAgent.match(/Android|iPhone|iPad/i) && store.state.managerInfo && eccode) {
        clearTimeout(requestControl.value)
        requestControl.value = setTimeout(() => {
          popupPosition.left = `${event.clientX - rect.left + 10}px`
          popupPosition.right = 'none'
          if (bottomSpace > 210 && rightSpace > 430) {
            openPopup.value = flag
            popupPosition.top = '100%'
            popupPosition.bottom = 'none'
          } else if (rightSpace > 430 && topSpace > 210) {
            openPopup.value = flag
            popupPosition.bottom = '100%'
            popupPosition.top = 'none'
          } else {
            openPopup.value = flag
            popupPosition.left = '-50px'
            popupPosition.top = '100%'
          }
        }, 500)
      }
    }

    /**
     * @description redirect the user to the profile name clicked
     * @param id ecCode of user.
     * @param e Event of DOM
     */
    function linkProfileUser (id, e, label = true) {
      e.preventDefault()
      e.stopPropagation()
      if (!label) return
      const url = process.env.VUE_APP_NODE_ENV === 'prd' ? `https://people.grey.com/profile/${id}` : `https://${process.env.VUE_APP_NODE_ENV}-people.grey.com/profile/${id}`
      window.open(url, '_self')
    }

    /**
     * @description When user press right click to get, copy or open in a new tab the path related the element of DOM in <a> tag
     * @param e of DOM
     * @param id ecCode of user to buil the url to update
     */
    function rightClick (e, id) {
      const url = process.env.VUE_APP_NODE_ENV === 'prd' ? `https://people.grey.com/profile/${id}` : `https://${process.env.VUE_APP_NODE_ENV}-people.grey.com/profile/${id}`
      store.getters.updateHref(e, url)
    }

    /**
     * @description show toast with delete item fail message.
     */
    function showDeleteErrorToast () {
      deleteErrorToast.value = true
      setTimeout(() => {
        deleteErrorToast.value = false
      }, 5000)
    }

    /**
     * @description Open/Close likes modal
     * @param flag flag indicating if open or close
     */
    function toggleLikesModal (flag) {
      likesModalVisible.value = flag
    }

    /**
     * @description Updates the list of comments when user adds new comments.
     */
    function updateCommentList () {
      callGetDetailComments().then(response => {
        comments.value = response.data.comments
        store.commit({
          type: 'UPDATE_COMMENTS_AMOUNT',
          id: route.params.idCard,
          commentsAmount: comments.value.length
        })
      })
    }

    /**
     * @description update comment content with edited value
     * @param commentEdited new comment text
     * @param commentDate new comment date
     */
    function updateCommentContent (commentEdited, commentDate, index) {
      comments.value[index].content = commentEdited
      comments.value[index].date = commentDate
    }

    /**
     * @description Updates the collection data to check if it contains item id
     * @param collectionIndex index of the collection to update
     */
    function updateCollectionContainId (collectionIndex) {
      store.commit({
        type: 'ADD_REMOVE_ITEM_FROM_COLLECTION',
        positionCollection: collectionIndex
      })
    }

    return {
      store,
      router,
      route,
      resultItem,
      openPopup,
      popupPosition,
      requestControl,
      position,
      authorsCollaboratorsInfo,
      errorMessageToast,
      deleteErrorToast,
      likesModalVisible,
      article,
      comments,
      infoLoaded,
      portfolioModal,
      deleteItemModal,
      openCollectionsModal,
      openAddToPortfolioModal,
      openDeleteModal,
      openPopupPersonalInfo,
      linkProfileUser,
      rightClick,
      showDeleteErrorToast,
      toggleLikesModal,
      updateCommentList,
      updateCommentContent,
      backToCollectionModal,
      forwardToCreateCollectionModal,
      createCollectionModalVisible,
      toggleCreateCollectionsModal,
      goBack,
      requestAccessObject,
      itemHasStreamingUrls,
      azureMediaPlayer,
      userDataLoaded,
      profileLink,
      updateCollectionContainId,
      scrollToTop,
      scrollY
    }
  }
}
</script>
