<template>
  <section class="containerCardImage" :class="{'highlight': isHighlight}">
    <img class="containerSearchCard__staticImage" :src="image" v-if="!carrouselHasImages"/>
    <section class="containerCardImage__carrousel" :id="`carrousel-${indexItem}`" v-if="carrouselHasImages">
      <div v-for="(slideImage, index) in carrouselImages" :key="index" class="containerCardImage__carrousel--slide fade">
        <img :src="slideImage" class="image">
      </div>
    </section>
    <section class="containerCardImage__dots" :id="`dots-${indexItem}`" v-if="carrouselHasImages">
      <span v-for="index in carrouselImages.length" :key="index" @click.stop="setActiveSlide($event, index)" class="containerCardImage__dots--dot"></span>
    </section>
    <section class="containerCardImage__video" v-if="fileTypeIs('Video')">
      <span class="grey-icon-camera"></span>
    </section>
  </section>
</template>

<script>
import { onMounted, computed, nextTick, ref } from 'vue'
export default {
  name: 'cardImages',
  props: {
    item: {
      type: Object,
      required: true
    },
    indexItem: {
      type: Number,
      default: 16
    }
  },
  setup (props) {
    const image = ref(null)
    const carrouselImages = ref([])
    const slideIndex = ref(0)
    const slides = ref([])
    const dots = ref([])
    const requestControl = ref(null)

    /**
     * @description Returns a flag indicating if autoplay should be set or not.
     */
    const autoPlayCarrousel = computed(() => {
      return carrouselImages.value && carrouselImages.value.length > 1
    })

    /**
     * @description Returns a flag indicating if carrousel has images
     */
    const carrouselHasImages = computed(() => {
      return !image.value && carrouselImages.value && carrouselImages.value.length > 0
    })

    /**
     * @description checks if item source is Go Grey Highlights
     */
    const isHighlight = computed(() => {
      return props.item.sourceTitle && props.item.sourceTitle === 'HIGHLIGHT'
    })

    /**
     * @description Check if item file type is video
     * @param fileTyparam file type to evaluate
     */
    function fileTypeIs (fileTyparam) {
      const fieldsToCheck = props.item.source === 'news' ? [props.item.content_type] : [props.item.file_type, props.item.internal_category]
      return fieldsToCheck.includes(fileTyparam)
    }

    onMounted(() => {
      setUpImage()
    })

    /**
     * @description Sets the image to be used for the item.
     */
    function setUpImage () {
      image.value = props.item.thumbnail_url
      switch (props.item.source) {
        case 'persona':
          image.value = props.item.midsize_picture
          break
        case 'intelligence':
        case 'sharepoint':
        case 'work':
          if ((fileTypeIs('PDF') || fileTypeIs('Presentation')) && Array.isArray(props.item.previews) && props.item.previews.length && !(props.item.thumbnail_selected_by_user || props.item.thumbnailSelectedByUser)) {
            if (props.item.previews.length > 1) {
              image.value = null
              carrouselImages.value = props.item.previews.filter(preview => preview)
              nextTick(() => {
                slides.value = document.getElementById(`carrousel-${props.indexItem}`).getElementsByClassName('containerCardImage__carrousel--slide')
                dots.value = document.getElementById(`dots-${props.indexItem}`).getElementsByClassName('containerCardImage__dots--dot')
                slides.value[0].style.display = 'contents'
                dots.value[0].className += ' active'
                setUpCarrousel()
              })
            } else {
              if (props.item.previews[0] !== 'null') image.value = props.item.previews[0]
            }
          }
          break
      }
    }

    /**
     * @description Set carrousel images
     * @param clarTimer flag indicating if reset timer variable
     */
    function setUpCarrousel (clearTimer = false) {
      if (clearTimer) clearTimeout(requestControl.value)
      requestControl.value = setTimeout(() => {
        Array.from(slides.value).forEach(slide => {
          slide.style.display = 'none'
        })
        slideIndex.value++
        if (slideIndex.value > slides.value.length) slideIndex.value = 1
        Array.from(dots.value).forEach(dot => {
          dot.className = dot.className.replace(' active', '')
        })
        slides.value[slideIndex.value - 1].style.display = 'contents'
        dots.value[slideIndex.value - 1].className += ' active'
        setUpCarrousel()
      }, 10000)
    }

    /**
     * @description Set a specific active slide
     * @param indexParam index of the slide to set as active
     */
    function setActiveSlide (event, indexParam) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      slideIndex.value = indexParam
      Array.from(slides.value).forEach(slide => {
        slide.style.display = 'none'
      })
      Array.from(dots.value).forEach(dot => {
        dot.className = dot.className.replace(' active', '')
      })
      slides.value[indexParam - 1].style.display = 'contents'
      dots.value[indexParam - 1].className += ' active'
      setUpCarrousel(true)
    }

    return {
      image,
      slideIndex,
      carrouselImages,
      autoPlayCarrousel,
      carrouselHasImages,
      setUpCarrousel,
      setActiveSlide,
      fileTypeIs,
      isHighlight
    }
  }
}
</script>
