<template>
  <section class="containerRequestLabels" v-if="accessFileControl.showContent">
    <a class="containerRequestLabels__link pointer"
      :href="accessFileControl.sharepointUrl"
      target="_blank"
      v-if="accessFileControl.allowedView"
    > Open in sharepoint <span class="grey-icon-arrow-pagination containerRequestLabels__link--arrow"></span>
    </a>
    <template v-else-if="!accessFileControl.allowedView && !accessFileControl.hasRequestedAccess && !accessFileControl.requestProgress">
      <span class="grey-icon-padlock"></span>
      <span class="containerRequestLabels__label">This file is private, please <span class="link pointer" @click="requestToAccess">request access.</span></span>
    </template>
    <span class="containerRequestLabels__success" v-else-if="accessFileControl.hasRequestedAccess">Your request is in progress</span>
    <div class="containerRequestLabels__spinner" v-if="showSpinner">
      <spinner />
    </div>
    <span class="containerRequestLabels__success" v-if="accessFileControl.successRequest">Your request has been sent</span>
  </section>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'requestToAccess',
  components: {
    spinner
  },
  props: {
    cardId: {
      type: String,
      required: true
    },
    requestAccessObject: {
      type: Object,
      default: null
    }
  },
  emits: ['authorsCollaboratorsInfo'],
  setup (props, { emit }) {
    const store = useStore()
    const accessFileControl = reactive({
      allowedView: null,
      hasRequestedAccess: null,
      sharepointUrl: '',
      requestProgress: null,
      successRequest: null,
      showContent: null
    })
    const showSpinner = ref(null)

    onMounted(() => {
      accessFileControl.allowedView = props.requestAccessObject.allowedView
      accessFileControl.hasRequestedAccess = props.requestAccessObject.hasRequestedAccess
      accessFileControl.sharepointUrl = props.requestAccessObject.sharepointUrl
      accessFileControl.showContent = true
    })

    /**
     * @description Request to acces call
     */
    function requestToAccess () {
      showSpinner.value = accessFileControl.requestProgress = true
      store.dispatch({
        type: 'requestAccess',
        indexId: props.cardId
      }).then(
        (response) => {
          showSpinner.value = false
          accessFileControl.successRequest = true
        },
        (error) => {
          console.error(error)
          accessFileControl.requestProgress = showSpinner.value = false
        }
      )
    }

    return {
      store,
      accessFileControl,
      showSpinner,
      requestToAccess
    }
  }
}
</script>
